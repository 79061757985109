
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function App() {
  return (
    <Container maxWidth={false} sx={{ pt: 6, pb: 6, backgroundColor: '#C7CAD1', minHeight: '300px' }}>
      <Container maxWidth='lg'>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} sm={12} md={6}>
            <Stack spacing={8}>
              <Typography variant='h5' sx={{ fontSize: 30 }}>
                Dion Dental Studio
              </Typography>
              <Stack spacing={2}>
                <Typography variant='body2'>
                  17817 Leslie St. Unit #26
                </Typography>
                <Typography variant='body2'>
                  Newmarket Ontario L3Y 8C6
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
  // TODO: Add google maps pin
}
