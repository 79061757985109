
import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function AboutUsRegion() {
  return (
    <Box sx={{ pb: 8, pt: 8, backgroundColor: '#f3f3f3', px: 4, minHeight: '450px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Container maxWidth='lg' sx={{ py: 4 }}>
        <Box sx={{ pr: 2 }}>
          <Stack spacing={2}>
            <Typography variant='h3'> 
              About Us
            </Typography>
            <Typography variant='body1'> 
              Dion Dental Studio, formerly known as NDL, is a family owned dental lab that has been
              loyally serving the Newmarket area since 1974. The founder, Nikola Vrdoljak was a dentist in
              former Yugoslavia that came to Canada with a dream of a better life. Coming to Canada with
              no recognition of his degree, Nikola had to start from scratch. This did not stop him, as he
              ventured back to school to not only receive his dental technology degree, but also a degree
              in denturism. 
            </Typography>
            <Typography variant='body1'> 
              Nikola, along with his son Niksa Vrdoljak spent years working in the dental
              technology field mastering their crafts until finally deciding to open a lab of their own in
              Newmarket. They went on to form both professional and personal relationships with the
              dentists around them, and eventually passed the business down to Dion Vrdoljak, Niksas
              oldest son. 
            </Typography>
            <Typography variant='body1'> 
              Since being immersed in the dental environment at a young age, Dion has
              dedicated his life to developing his skills and mastering the craft. Channelling the passion
              and knowledge that have been passed onto him from his father and grandfather, Dion looks
              to carry on the family tradition and looks to continue to grow and learn along the way.
            </Typography>
          </Stack>
          </Box>
      </Container>
    </Box>
  );
};
