
import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import service1Image from '../assets/service1.jpg';
import service2Image from '../assets/service3.jpg';
import service3Image from '../assets/service2.jpg';

export default function ScriptRegion() {
  return (
    <Box sx={{ pb: 8, pt: 8, backgroundColor: '#000', px: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Container maxWidth='lg' sx={{ pb: 4, pt: 4}}>
        <Typography variant='h3' sx={{ py: 0, color: '#fff' }}>
          Services
        </Typography>

        <Grid container spacing={{ xs: 4, sm: 4, md: 8, lg: 8, xl: 8 }} direction="row" alignItems="center" sx={{ pt: 8, pl: 0 }} >
          <Grid item xs={12} sm={12} md={6} order={1} >
            <Box sx={{ marginRight: 'auto', marginLeft: { sm: 'auto', xs: 'auto', md: '0'} }} style={{  maxWidth: '700px',  minWidth: '250px', padding: 0 }}>
              <img alt='crown' src={service1Image} style={{ maxWidth: '100%' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} order={2}>
            <Stack spacing={4}>
              <Typography variant='h4' sx={{ color: '#fff' }}>
                Crown & Bridge/Implants
              </Typography>
            </Stack>
          </Grid>

        </Grid>
        <Grid container spacing={{ xs: 4, sm: 4, md: 8, lg: 8, xl: 8 }} direction="row" alignItems="center" sx={{ pt: 10, pl: 0 }} >
          <Grid item xs={12} sm={12} md={6} order={{ xs: 4, sm: 4, md: 3 }}>
            <Stack spacing={4}>
              <Typography variant='h4' sx={{ color: '#fff' }}>
                Nightguards & Orthodontics 
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} order={{ xs: 3, sm: 3, md: 4 }}>
            <Box sx={{ marginLeft: 'auto', marginRight: { sm: 'auto', xs: 'auto', md: '0'} }} style={{  maxWidth: '700px', minWidth: '250px',  padding: 0 }}>
              <img alt='team' src={service3Image} style={{ maxWidth: '100%' }} />
            </Box>
          </Grid>

        </Grid>
        <Grid container spacing={{ xs: 4, sm: 4, md: 8, lg: 8, xl: 8 }} direction="row" alignItems="center" sx={{ pt: 10, pl: 0 }} >
          <Grid item xs={12} sm={12} md={6} order={5}>
            <Box sx={{ marginRight: 'auto', marginLeft: { sm: 'auto', xs: 'auto', md: '0'} }} style={{  maxWidth: '700px', minWidth: '250px',  padding: 0 }}>
              <img alt='team' src={service2Image} style={{ maxWidth: '100%' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} order={6}>
            <Stack spacing={4}>
              <Typography variant='h4' sx={{ color: '#fff' }}>
                Dentures
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
