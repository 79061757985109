
import * as React from 'react';
import ReactGA from 'react-ga';

import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import UpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function ScrollTop(props: Props) {
  const { window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    ReactGA.event({
      category: 'UA',
      action: 'Scrolled to top'
    }, [ 't1', 't2' ]);

    const anchor = document.querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Fab
        sx={{ position: 'fixed', bottom: 20, right: 20 }}
        color='secondary'
        aria-label='scroll-to-top'
        size='medium'
        onClick={handleClick}
      >
        <UpIcon size='large' />
      </Fab>
    </Fade>
  );
};
