
import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ContactForm from '../components/ContactForm';


export default function ContactRegion() {
  return (
    <Box sx={{ py: 8, px: 4 }}>
      <Container maxWidth='lg' sx={{ py: 4 }}>
        <Grid container spacing={4} direction="row" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant='h3'>
              Contact
            </Typography>
            <Stack spacing={2}>
              <Typography variant='body1'>
                Please contact us with any questions.
              </Typography>
              <div>
                <Typography variant='body1'>
                  <b>Email</b>
                </Typography>
                <Typography variant='body1'>
                  ndl.prodent@yahoo.ca
                </Typography>
              </div>
              <div>
                <Typography variant='body1'>
                  <b>Phone</b>
                </Typography>
                <Typography variant='body1'>
                  (905) 895 1662
                </Typography>
              </div>
              <div>
                <Typography variant='body1'>
                  <b>Toll Free</b>
                </Typography>
                <Typography variant='body1'>
                  1 (888) 849 4246
                </Typography>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
