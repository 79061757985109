
import React from 'react';
import ReactGA from 'react-ga';
import emailjs from '@emailjs/browser';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const SERVICE_ID = 'service_nztr3of';
const TEMPLATE_ID = 'template_wajpigb';
const PUBLIC_KEY = 'T25bB89UHs2eakRK2';

const subjectItems = [
  "General Inquiry",
  "Price List",
  "Services",
  "Other"
];

const initForm = {
  name: '',
  email: '',
  subject: subjectItems[0],
  message: ''
};

const initEmailStatus = {
  error: false,
  message: ''
};

const successEmailStatus = {
  error: false,
  message: 'Message sent successfully!'
};

const errorEmailStatus = {
  error: true,
  message: 'Message failed to send. Please contact the email listed directly.'
};

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
};

export default function ContactForm() {
  const [form, setForm] = React.useState(initForm);
  const [errors, setErrors] = React.useState({});
  const [emailStatus, setEmailStatus] = React.useState(initEmailStatus);

  const handleSubmit = e => {
    e.preventDefault();

    setErrors({});

    let newErrors = {};
    if (!form.name) newErrors.name = 'Please enter your name!';

    if (!form.email) newErrors.email = 'Please enter your email address!';
    else if (!validateEmail(form.email)) newErrors.email = 'Please enter a valid email address';

    if (!form.message) newErrors.message = 'Please enter your message!';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_KEY)
        .then((result) => {
          setForm(initForm);
          setEmailStatus(successEmailStatus);
          ReactGA.event({
            category: 'UA',
            action: 'Contact Request Success'
          }, [ 't1', 't2' ]);
        }, (error) => {
          setEmailStatus(errorEmailStatus);
          ReactGA.event({
            category: 'UA',
            action: 'Contact Request Failed'
          }, [ 't1', 't2' ]);
        });
    } else {
      setEmailStatus(initEmailStatus);
      ReactGA.event({
        category: 'UA',
        action: 'Attempt contact with errors'
      }, [ 't1', 't2' ]);
    }
  };

  return (
    <Box component='form' novalidate autoComplete='off'>
      <Stack spacing={2}>
        <TextField
          id='ct-name'
          label='Name'
          value={form.name}
          variant='outlined'
          error={errors.name ? true : false}
          helperText={errors.name}
          onChange={e => setForm({...form, name: e.target.value})}
          fullWidth
        />
        <TextField
          id='ct-email'
          label='Email'
          value={form.email}
          variant='outlined'
          error={errors.email ? true : false}
          helperText={errors.email}
          onChange={e => setForm({...form, email: e.target.value})}
          fullWidth
        />
        <TextField
          id='ct-subject'
          select
          label='Subject'
          value={form.subject}
          onChange={e => setForm({...form, subject: e.target.value})}
          fullWidth
        >
          { subjectItems.map((e, i) => (
            <MenuItem value={e} key={i}>{e}</MenuItem>
          ))}
        </TextField>
        <TextField
          id='ct-message'
          label='Message'
          value={form.message}
          variant='outlined'
          error={errors.message ? true : false}
          helperText={errors.message}
          multiline
          rows={6}
          onChange={e => setForm({...form, message: e.target.value})}
          fullWidth
        />
        <Button sx={{ fontSize: 16, height: '80px', width: '250px' }} color='secondary' variant='contained' onClick={handleSubmit} >
          Send Message
        </Button>
        <Typography variant='body1' sx={{ color: emailStatus.error ? '#d32f2f' : '#000' }}>
          {emailStatus.message}
        </Typography>
      </Stack>
    </Box>
  );
};
