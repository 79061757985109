
import { createTheme } from '@mui/material/styles';

const theme = createTheme({      
  palette: {
    primary: {
      main: '#181619'
    },
    secondary: {
      main: '#DDA74F'
    },
  },
  textField: {
    backgroundColor: 'red'
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
    body1: {
      color: '#0D0D0B',
    },
    body2: {
      color: '#0D0D0B',
      fontWeight: 100
    },
    h4: {
      color: '#0D0D0B',
      paddingBottom: '20px'
    },
    h3: {
      color: '#0D0D0B',
      paddingBottom: '20px'
    },
    button: {
      textTransform: 'none'
    }
  }
});

export default theme;
