
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import heroImage from '../assets/DionDentalStudio.jpg';

export default function HeroImage() {
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ 
          zIndex: -11,
          backgroundColor: '#000',
          height: '60vh',
          minHeight: '500px'
      }}
    >
      <div style={{
          zIndex: 1,
          backgroundColor: '#000',
          backgroundImage: `url(${heroImage})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          height: '60vh',
          width: '100%',
          minHeight: '500px',
          maxWidth: '1536px',
          backgroundSize: 'cover',
          margin: '-80px 0 0 0',
        }}
      />
      <Container
        maxWidth='lg'
        sx={{
          mt: { xs: '-200px', sm: '-140px', md: '-80px' }
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: 'white',
            textAlign: 'center'
          }} 
        >
          Dental technology personalized.
        </Typography>
      </Container>
    </Container>
  );
}
