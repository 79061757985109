
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme';
import LandingPage from './pages/LandingPage';

import ReactGA from 'react-ga';
ReactGA.initialize([{
    trackingId: 'UA-234668130-2',
    gaOptions: { name: 't1' }
  }, {
    trackingId: 'UA-238368512-1',
    gaOptions: { name: 't2' }
  }
]);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/home' element={<LandingPage />} />
          <Route path='/*' element={<h2>404 Not Found</h2>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
