
import Container from '@mui/material/Container';

import Navbar from '../components/Navbar';
import HeroImage from '../components/HeroImage';
import AboutUsRegion from '../regions/AboutUsRegion';
import ServicesRegion from '../regions/ServicesRegion';
import ContactRegion from '../regions/ContactRegion';
import ScrollTop from '../components/ScrollTop';
import Footer from '../components/Footer';

export default function LandingPage() {
  return (
    <div>
      <Navbar transparent={true} />
      <HeroImage />
      <Container maxWidth={false} disableGutters>
        <div id='about'>
          <AboutUsRegion />
        </div>
        <div id='services'>
          <ServicesRegion />
        </div>
        <div id='contact'>
          <ContactRegion />
        </div>
        <ScrollTop />
      </Container>
      <Footer />
    </div>
  );
}
