
import React from 'react';
import ReactGA from 'react-ga';

import { HashLink as Link } from 'react-router-hash-link';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

import DownloadIcon from '@mui/icons-material/Download';
import MenuIcon from '@mui/icons-material/Menu';

import scriptPDF from '../assets/script.pdf';

const menuItems = [
  { text: "About", link: 'home#about' },
  { text: "Services", link: 'home#services' },
  { text: "Contact", link: 'home#contact' }
];

export default function Navbar({ transparent }) {

  const logGAEvent = (action) => {
    ReactGA.event({
      category: 'UA',
      action: action
    }, [ 't1', 't2']);
  };

  const [isOpen, setOpen] = React.useState(false);

  const toggleDrawer = open => e => {
    setOpen(open);
  };

  return (
    <React.Fragment>
      <AppBar
        position='relative'
        sx={{ zIndex: 20, background: 'none', backgroundColor: transparent ? 'none' : '#f3f3f3' }} elevation={0}
      >
        <Container maxWidth={false} disableGutters>
          <Toolbar sx={{ height: '75px', display: 'in-line' }} id='back-to-top-anchor' >
            <IconButton
              edge='start'
              aria-label='menu'
              onClick={toggleDrawer(true)}
              sx={{ color: !transparent ? '#000' : '#fff', display: { md: 'none' }}} >
              <MenuIcon />
            </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontSize: 26, flexGrow: 1, textAlign: { xs: 'center', sm: 'center', md: 'left' }, display: 'block' }}
              >
                <Link to='/home' style={{ color: !transparent ? '#000' : "#fff", textDecoration: 'none' }}>
                  Dion Dental Studio
                </Link>
              </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
              {menuItems.map((e, i) => (
                <Link to={`/${e.link}`} style={{ textDecoration: 'none' }} smooth key={i}>
                  <Button
                    key={i}
                    sx={{ color: !transparent ? '#000' : '#fff', fontSize: 18, pr: 2 }}
                    onClick={_ => logGAEvent(`Scrolled to ${e.text}`)}
                  >
                    {e.text}
                  </Button>
                </Link>
              ))}
              <a style={{ color: '#000', textDecoration: 'none' }} href={scriptPDF} download='9488 Dion Dental Rx.pdf'>
                <Button
                  sx={{ color: !transparent ? '#000' : '#fff', fontSize: 18 }}
                  startIcon={<DownloadIcon sx={{ minWidth: '15px'}} />}
                  onClick={_ => logGAEvent('Download Script')}
                >
                  Scripts
                </Button>
              </a>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor='top' open={isOpen} onClose={toggleDrawer(false)}>
        <div
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          style={{
            backgroundColor: transparent ? '#000' : '#f3f3f3',
          }}
        >
          <List>
            { menuItems.map((e, i) => (
              <Link style={{ textDecoration: 'none' }} to={`/${e.link}`} key={i}>
                <ListItem
                  button
                  key={i}
                  onClick={_ => logGAEvent(`Scrolled to ${e.text} [small]`)}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="h6" style={{ color: transparent ? '#fff' : '#000' }}>
                        {e.text}
                      </Typography>

                    }
                  />
                </ListItem>
              </Link>
            ))}
            <a style={{ color: '#000', textDecoration: 'none' }} href={scriptPDF} download='9488 Dion Dental Rx.pdf'>
              <ListItem
                button
                onClick={_ => logGAEvent('Download Script [small]')}
              >
                <ListItemIcon style={{ color: transparent ? '#fff' : '#000', minWidth: '30px' }}>
                  <DownloadIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="h6" style={{ color: transparent ? '#fff' : '#000' }}>
                      Scripts
                    </Typography>
                  }
                />
              </ListItem>
            </a>
          </List>
        </div>
      </Drawer>

    </React.Fragment>
  );
}
